import { Image, Space, Stack, Text } from '@mantine/core';
import type { FC } from 'react';
import { memo } from 'react';
import { MainButton } from 'src/components/button/MainButton';
import { TextButton } from 'src/components/button/TextButton';
import { MainModal } from 'src/components/modal/MainModal';
import { COLOR } from 'src/constants';

type HowToUseModalPropsType = {
  isFromHowToUsePage: boolean;
  isOpen: boolean;
  onClick: VoidFunction;
  onClose: VoidFunction;
};

export const HowToUseModal: FC<HowToUseModalPropsType> = memo(({ isFromHowToUsePage, isOpen, onClick, onClose }) => {
  return (
    <MainModal
      isOpen={isOpen}
      onClose={onClose}
      isCloseOnClickOutside={false}
      px={0}
      title={`V2AEONMALLアプリへ${'\n'}ようこそ`}
      mainButton={<MainButton onClick={onClick}>使い方を確認する</MainButton>}
      subButton={isFromHowToUsePage ? <TextButton onClick={onClose}>戻る</TextButton> : undefined}
    >
      <Stack px={26} pt={10}>
        <Text data-autofocus size={14} sx={{ fontWeight: 500 }}>
          V2AEONMALLとは、ご家庭で発電した電力を電気自動車でイオンモールに放電していただくと、イオンモールの脱炭素社会に向けた取り組みにご協力いただいたお礼としてWAON
          POINTを進呈するサービスです。
        </Text>
        <Text color={COLOR.MAIN} size={13} sx={{ fontWeight: 400 }}>
          ※ 現在は一部イオンモールのみで導入しております。詳しくはスタンド利用状況からご確認ください。
        </Text>
      </Stack>
      <Space h={6} />
      <Image src={`${process.env.PUBLIC_URL}/image/car.png`} width="100%" />
      <Space h={6} />
    </MainModal>
  );
});
HowToUseModal.displayName = 'HowToUseModal';
