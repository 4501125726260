export const STORE_AREA = [
  { label: '関東', value: 'kanto' },
  { label: '近畿', value: 'kinki' },
];

export const STORE_INFO_KINKI = [
  {
    address: '三重県鈴鹿市',
    installationId: 'A0000001105',
    name: 'イオンモール鈴鹿',
    stands: [1, 2],
  },
  {
    address: '大阪府堺市',
    installationId: 'A0000001017',
    name: 'イオンモール堺北花田',
    stands: [1, 2],
  },
  {
    address: '大阪府堺市',
    installationId: 'A0000001155',
    name: 'イオンモール堺鉄砲町',
    stands: [1, 2],
  },
  {
    address: '大阪府四条畷市',
    installationId: 'A0000001152',
    name: 'イオンモール四條畷',
    stands: [1, 2],
  },
  // {
  //   address: '大阪府泉南市',
  //   installationId: 'A0000001119',
  //   name: 'イオンモールりんくう泉南',
  //   stands: [1, 2],
  // },
  {
    address: '奈良県橿原市',
    installationId: 'A0000001012',
    name: 'イオンモール橿原',
    stands: [1, 2],
  },
  {
    address: '奈良県大和郡山市',
    installationId: 'A0000001134',
    name: 'イオンモール大和郡山',
    stands: [1, 2],
  },
  {
    address: '京都府京都市',
    installationId: 'A0000001148',
    name: 'イオンモール京都桂川',
    stands: [1, 2],
  },
] as const;

export const STORE_INFO_KANTO = [
  {
    address: '千葉県千葉市',
    installationId: 'A0000001146',
    name: 'イオンモール幕張新都心',
    stands: [1, 2],
  },
] as const;

export const ALL_STORE_INFO = [...STORE_INFO_KANTO, ...STORE_INFO_KINKI] as const;
